import api from '../mgrApiIndex'

const ofsMgr = {
    // 登录
    orderStat(data) {
        return api({
            url: 'v2/mgr/order-stat',
            method: 'post',
            data: data
        })
    },
    orderList(data) {
        return api({
            url: 'v2/mgr/order-list',
            method: 'post',
            data: data
        })
    },
    orderInfo(data) {
        return api({
            url: 'v2/Canteen/orderInfo',
            method: 'post',
            data: data
        })
    },
    orderEdit(data) {
        return api({
            url: 'v2/Canteen/orderEdit',
            method: 'post',
            data: data
        })
    },
    refundorder(data) {
        return api({
            url: 'v2/wx/refundorder',
            method: 'post',
            data: data
        })
    }
    
}
export default ofsMgr