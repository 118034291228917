<!-- 订单列表 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
      <div class="header-content">订单详情</div>
    </header>
    <div class="split-line" />
    <section class="page-info">
      <div v-if="loading" class="loading-class">
        <van-loading type="spinner" size="24px" />
      </div>
      <div v-else>
        <div class="delivery-info bill-creater-info">
          <div class="delivery-title" stylw="width:100%;">
            配送信息：
          </div>
          <div class="delivery-content">
            {{ order_info.province }} {{ order_info.city }} {{ order_info.county }} {{ order_info.address }}
          </div>
          <div class="delivery-content">
            {{ order_info.organ_name }} {{ order_info.branch_name }} 病房:{{ order_info.room_number }}
            病床:{{ order_info.bed_number }}
          </div>
          <div class="delivery-people">
            {{ order_info.consignee }} {{ order_info.phone_num }}
          </div>
        </div>
        <div class="bill-creater-info">
          <div class="bill-time" style="width:100%">
            订单编号：{{ order_info.order_sn }}
          </div>
          <div class="bill-time">
            订餐时间：{{ getTimeYMDHMS(order_info.insert_dt) }}
          </div>
          <div style="color:#FF3333">
            ￥{{ order_info.amount }}
          </div>

          <div v-if="order_info.state == 1 && order_info.pay_state == 1 && order_info.refund_state != 2"
            style="color:#1989fa;width:100%">
            订单状态：{{ order_info.delivery_state_str }}
          </div>
          <div v-else-if="order_info.state != 1" style="width:100%">
            订单状态：已取消
          </div>
          <div v-else-if="order_info.refund_state == 2" style="color:#FF3333;width:100%">
            订单状态：已退款（原路退回）
          </div>
          <div v-else-if="order_info.state == 1 && order_info.pay_state == 0 && order_info.delivery_state == 0"
            style="color:#FF3333;width:100%">
            订单状态：{{ order_info.pay_state_str }}
          </div>
          <div v-else style="color:#FF3333;width:100%">
            订单状态：{{ order_info.pay_state_str }}
          </div>
        </div>
        <div class="bill-detail">
          <div class="bill-content">
            订单详细
          </div>
          <div class="dishes-module" v-if="order_info.dishes_list != null && order_info.dishes_list.length > 0">
            <div class="dishes-item" v-for="item in order_info.dishes_list" :key="item.dishes_id">
              <div class="dishes-name">
                {{ item.dishes_name }}
              </div>
              <div class="dishes-num">
                X {{ item.dishes_num }}
              </div>
              <div class="dishes-price">
                ￥ {{ item.price }}
              </div>
            </div>
          </div>
          <div v-if="order_info.shop_type == 3">
            <div v-for="modeInfo in mode_list" :key="modeInfo.id" style="margin-top: 10px;">
              <div style="font-weight: bold;color: #42a5f5;">{{ modeInfo.name }}</div>
              <div>
                <span v-for="(dishesInfo, dishesIndex) in modeInfo.list" :key="dishesIndex">
                  {{ dishesIndex == 0 ? "" : "、" }}{{ dishesInfo.dishes_name }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="order_info.state != 3 && order_info.refund_state <= 0" class="refund-btn"
          style="padding-bottom:70px;">
          <van-button type="warning" plain hairline round size="small"
            style="width:120px;font-size:16px;line-height:20px" :loading="refund_loading" @click="handleCancelOrder()">
            {{ order_info.pay_state == 1 ? '退款' : '取消' }}
          </van-button>
        </div>
        <div class="opt-sline"
          v-if="order_info.state == 1 && order_info.delivery_state != 2 && order_info.pay_state > 0 && order_info.refund_state != 2" />
        <div class="sign-btn"
          v-if="order_info.state == 1 && order_info.delivery_state != 2 && order_info.pay_state > 0 && order_info.refund_state != 2">
          <div style="width:120px;">
            <div v-if="order_info.delivery_state == 1">
              <van-button type="success" round block size="small" style="font-size:18px;font-weight:bold;"
                :loading="opt_loading" @click="handleOrder(2, order_info.order_sn)">
                签 收
              </van-button>
            </div>
            <div v-else-if="order_info.delivery_state == 0">
              <van-button type="danger" round block size="small" style="font-size:18px;font-weight:bold;"
                :loading="opt_loading" @click="handleOrder(1, order_info.order_sn)">
                配 送
              </van-button>
            </div>
          </div>
        </div>
      </div>
      <van-popup v-model:show="refundShow" round position="bottom" class="refund-pop">
        <div class="pop-content">
          <div class="refund-title">
            发起退款
          </div>
          <div>
            <van-form @submit="onSubmit">
              <van-cell-group inset :border="false">
                <van-field v-model="refund_amount" name="refund_amount" type="number" label="退款金额" required max="200"
                  placeholder="请输入退款金额" :formatter="formatter" format-trigger="onBlur" :rules="[{ required: true }]"
                  :border="false" />
                <div class="amount-attention">
                  最高可退￥{{ order_info.amount }}
                </div>
                <van-field v-model="refund_desc" name="refund_desc" label="退款说明" type="textarea" maxlength=50 rows="1"
                  autosize :border="false" placeholder="请输入退款说明" />
              </van-cell-group>
              <div class="refund-attention">
                <van-icon name="info-o" color="#E6A23C" />
                <span>退款成功后，金额将原路退回到相应账户</span>
              </div>
              <div class="refund-sure-opt">
                <van-button :loading="refund_loading" round block type="success" size="small" class="btn-class"
                  @click="onCancel">
                  取消
                </van-button>
                <van-button :loading="refund_loading" round block type="danger" size="small" class="btn-class"
                  native-type="submit">
                  确定
                </van-button>
              </div>
            </van-form>
          </div>
          <div class="bottom-space" />
        </div>
      </van-popup>
    </section>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { getTimeYMDHMS } from "@/utils/util"
import { getStorage } from '@/utils/localStorageUtils'
import CommonData from '@/utils/commonMgrData'
import { Toast, Dialog } from 'vant'
import ofsMgr from "@/api/ofsMgr/ofsMgr"

export default {
  name: "orderMgrInfo",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
      if (options && options.order_sn) {
        data.order_sn = options.order_sn
        fetchData()
      } else {
        Toast.loading({
          message: '参数异常，请稍后再试',
          forbidClick: true,
          duration: 0
        });
        setTimeout(() => {
          Toast.clear()
          $router.go(-1)
        }, 1000)
      }
    });

    const formatter = (value) => {
      var refund_amount = parseFloat(value)
      var amount = parseFloat(data.order_info.amount)
      console.log(amount, refund_amount)
      if (refund_amount < 0) {
        return '0'
      } else if (refund_amount > amount) {
        return amount + ''
      } else {
        return refund_amount + ''
      }
    }

    const data = reactive({
      order_sn: '',
      order_info: {},
      mode_list: [],
      loading: true,
      opt_loading: false,
      refund_loading: false,
      refundShow: false,
      refund_amount: 0,
      refund_desc: ''
    });
    const fetchData = () => {
      data.loading = true
      var post_data = {
        order_sn: data.order_sn
      }
      ofsMgr.orderInfo(post_data).then(res => {
        data.order_info = res.data
        var modeObj = {};
        for (var i = 0; i < res.data.mode_list.length; i++) {
          var modeInfo = res.data.mode_list[i];
          var modeKey = "mode_" + modeInfo.meal_mode;
          if (!Object.keys(modeObj).includes(modeKey)) {
            modeObj[modeKey] = { id: modeInfo.meal_mode, name: modeInfo.mode_name, list: [] };
          }
          console.log('modeKey', modeObj)
          modeObj[modeKey].list.push(modeInfo);
        }
        data.mode_list = Object.keys(modeObj).map((key) => modeObj[key]);
        data.loading = false
      })
    }
    const handleOrder = (type, order_sn) => {
      console.log(type)
      var title = ''
      if (type == 1) { // 配送
        title = '是否确定开始配送？'
      } else if (type == 2) { //
        title = '是否确定正常签收？'
      }
      Dialog({
        message: title,
        showCancelButton: true,
      })
        .then(() => {
          data.opt_loading = true
          const post_data = {
            order_sn: order_sn,
            delivery_state: type,
            courier: getStorage(CommonData.KeyStorages.KEY_MGR_USER_NAME),
            state: 1
          }
          console.log(post_data)
          ofsMgr.orderEdit(post_data).then(res => {
            Toast('操作成功');
            data.opt_loading = false
            fetchData()
          }).catch(err => {
            Toast(err.message);
            data.opt_loading = false
          })

        })
        .catch(() => {
          // on cancel
        });
    }
    const handleCancelOrder = () => {
      data.refund_amount = data.order_info.amount
      data.refundShow = true
    }
    const onCancel = () => {
      data.refundShow = false
    }
    const onSubmit = () => {
      if (parseFloat(data.refund_amount) <= 0 || parseFloat(data.refund_amount) > parseFloat(data.order_info.amount)) {
        Toast('退款金额异常，请确认后重试！');
        return
      }
      data.refund_loading = true
      const post_data = {
        module_id: (data.order_info.shop_type == null || data.order_info.shop_type == 1 || data.order_info.shop_type == 3) ? 102 : 103,
        module_sn: data.order_info.order_sn,
        refund: parseInt(data.refund_amount * 100),
        reason: data.refund_desc
      }
      console.log(post_data)
      ofsMgr.refundorder(post_data).then(res => {
        data.refund_loading = false
        onCancel()
        fetchData()
      }).catch(res => {
        data.refund_loading = false
      })
    }
    return {
      ...toRefs(data),
      formatter,
      getTimeYMDHMS,
      handleOrder,
      handleCancelOrder,
      onCancel,
      onSubmit
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  background: #eee;

  .page-header {
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;

    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }

    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }

  .split-line {
    width: 100%;
    border-top: thin solid #DCDCDC;
  }

  .page-info {
    // width: 100%;
    // padding: 10px;
    margin: 0 10px;

    .loading-class {
      margin-top: 33%;
      width: 100%;
      text-align: center;
    }

    .delivery-info {
      background: #fafafa;
      border-radius: 12px;
      font-size: 14px;
      line-height: 25px;
      padding: 10px;
      color: #666666;

      .delivery-title {
        font-weight: 800;
        color: #FF3333;
      }

      .delivery-content {
        color: #000000;
        line-height: 20px;
      }

      .delivery-people {
        color: #000000;
        font-size: 12px;
      }
    }

    .bill-creater-info {
      margin: 15px 0;
      border-radius: 20px;
      background: #fafafa;
      padding: 15px;
      font-size: 14px;
      line-height: 24px;
      color: #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .bill-detail {
      .bill-content {
        font-size: 16px;
        line-height: 25px;
        font-weight: bold;
      }

      .dishes-module {
        margin-top: 10px;

        .dishes-item {
          font-size: 14px;
          color: #666;
          line-height: 20px;
          display: flex;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: space-between;

          .dishes-num {
            font-weight: 500;
            color: #000;
          }

          .dishes-price {
            font-weight: 500;
            color: #000;
          }
        }
      }
    }

    .refund-btn {
      margin-top: 20px;
      float: right;
    }

    .opt-sline {
      height: 40px;
    }

    .sign-btn {
      height: 40px;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 20px;
      justify-content: right;
    }

    .refund-pop {
      width: 100%;

      .pop-content {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        line-height: 20px;

        .refund-title {
          width: 100%;
          text-align: center;
          font-weight: bold;
        }

        .refund-attention {
          font-size: 14px;
          line-height: 20px;
          padding: 10px 0;
          color: #AAA;
        }

        .amount-attention {
          font-size: 14px;
          color: #aaa;
          float: right;
        }

        .refund-sure-opt {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;

          .btn-class {
            padding: 0 10px;
            width: 45%;
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
          }
        }
      }

      .bottom-space {
        height: 10px;
      }
    }
  }
}
</style>
